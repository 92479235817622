<p class="font-bold">
    To select Areas of Expertise, please choose a category from the dropdown menu. Once you have selected your area_of_expertise,
    click the “ADD” button to the right. Please repeat this process until you have listed all your areas of expertise.
    <strong>Limit of (5) selections.</strong>
</p>

<div class="m-b" ng-show="faculty_area_of_expertise.length < 5">
    <div class="hbox">
        <div class="col" style="width: 125px">
            <ui-select ng-model="new_area_of_expertise.selected" theme="bootstrap">
                <ui-select-match placeholder="Select or type to search ...">{{$select.selected.name}}
                </ui-select-match>
                <ui-select-choices repeat="option in area_of_expertise | filter: $select.search">
                    <div ng-bind-html="option.name | highlight: $select.search"></div>
                </ui-select-choices>
            </ui-select>
        </div>

        <div class="col" style="width: 275px" ng-show="new_area_of_expertise.selected.name">
            <div class="m-l">
                <ui-select ng-model="new_sub_area_of_expertise.selected" theme="bootstrap">
                    <ui-select-match placeholder="Select or type to search ...">{{$select.selected.name}}
                    </ui-select-match>
                    <ui-select-choices repeat="option in sub_area_of_expertise | filter: $select.search">
                        <div ng-bind-html="option.name | highlight: $select.search"></div>
                    </ui-select-choices>
                </ui-select>
            </div>
        </div>

        <div class="col" style="width: 350px" ng-show="new_sub_area_of_expertise.selected.name && sub_sub_area_of_expertise.length">
            <div class="m-l">
                <ui-select ng-model="new_sub_sub_area_of_expertise.selected" theme="bootstrap">
                    <ui-select-match placeholder="Select or type to search ...">{{$select.selected.name}}
                    </ui-select-match>
                    <ui-select-choices repeat="option in sub_sub_area_of_expertise | filter: $select.search">
                        <div ng-bind-html="option.name | highlight: $select.search"></div>
                    </ui-select-choices>
                </ui-select>
            </div>
        </div>

        <div class="col">
            <div class="m-l">
                <button ng-click="addAreasOfExpertise()" type="button" class="btn btn-sm btn-primary"><i
                        class="fa fa-plus fa-fw"></i> Add
                </button>
            </div>
        </div>
    </div>
</div>

<div class="font-bold">Areas Of Expertise you have selected:</div>
<div class="line line-dashed b-b b-primary2"></div>

<div ng-show="faculty_area_of_expertise.length == 0">None</div>

<!-- SELECTED AREAS OF EXPERTISE -->
<div ng-repeat="area_of_expertise in faculty_area_of_expertise track by $index">

    <div class="hbox m-b">
        <div class="col v-middle" style="width: 400px;">
            <span>{{$index + 1}}. {{area_of_expertise.name}}</span>
        </div>
        <div class="col v-middle">
            <div class="m-l">
                <button ng-click="removeAreasOfExpertise(area_of_expertise)" class="btn btn-xs btn-danger"><i
                        class="fa fa-times text-white"></i> Delete
                </button>
            </div>
        </div>
    </div>

    <div class="line line-dashed b-b b-primary2"></div>
</div>

